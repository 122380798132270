import querystring from "querystring";

//import { createI18n } from "vue-i18n";

import "../../assets/scss/loading.scss";

import { getClientIdCustomizations } from "./api";
import { createTooltip } from "./tooltip";

const ATTRIBUTE_PREFIX = "hellobb";

const DEFAULT_CUSTOMIZATIONS = {
    images: {
        logoUrl: "https://www.hellobb.net/favicon/favicon-96x96.png",
    },
    styles: {
        button: {
            backgroundColor: "#ffc944",
            borderRadius: 10,
            borderWidth: 1,
            borderColor: "transparent",
            fontFamily: "sans-serif",
            fontSize: 16,
            paddingBottom: null,
            paddingLeft: null,
            paddingRight: null,
            paddingTop: null,
            textColor: "#1e0028",
        },
    },
    tooltipEnabled: true,
};

function sendMessage(targetWindow, type, payload = null) {
    targetWindow.postMessage({
        source: "hellobb",
        type,
        payload,
    }, "*");
}

function renameHellobbAttribute(key) {
    if (!key.startsWith(ATTRIBUTE_PREFIX)) {
        return key;
    }

    let renamed = key.substring(ATTRIBUTE_PREFIX.length);
    renamed = renamed[0].toLowerCase() + renamed.substring(1);

    return renamed;
}

function getHellobbAttributes(dataset) {
    return Object.keys(dataset)
        .filter(key => key.startsWith(ATTRIBUTE_PREFIX))
        .reduce((result, key) => ({
            ...result,
            [renameHellobbAttribute(key)]: dataset[key],
        }), {});
}

function getHighestZIndex() {
    return Array.from(
        document.querySelectorAll("*")
    )
        .filter(el => !Array.from(el.classList).some(className => className.toLowerCase().indexOf("hellobb") !== -1))
        .map(el => window.getComputedStyle(el).getPropertyValue("z-index"))
        .sort()
        .filter((value, i, arr) => arr.indexOf(value) === i)
        .map(zIndex => (zIndex|0))
        .reduce((a, b) => Math.max(a, b), 0);
}

function updateHellobbZIndex() {
    const zIndex = Math.max(1000, 1 + getHighestZIndex());

    Array.from(
        document.querySelectorAll(".hellobb-tooltip, .hellobb-modal-container")
    ).forEach((el) => {
        const currentZIndex = window.getComputedStyle(el).getPropertyValue("z-index") | 0;

        el.style.zIndex = Math.max(zIndex, currentZIndex);
    });
}

function getFonts() {
    const fonts = {};

    Array.from(document.styleSheets).forEach((stylesheet) => {
        const rules = Array.from(stylesheet.rules || stylesheet.cssRules || []);

        rules
            .filter(rule => rule.constructor.name === "CSSFontFaceRule")
            .forEach((rule) => {
                const key = rule.style.fontFamily;
                const value = rule.cssText;

                if (value.indexOf("//") === -1) {
                    // We want to have at least one absolute URL in the CSS rules.
                    //
                    // Relative URLs won't work because they will be loaded
                    // using the HelloBB domain as reference, so this should
                    // reduce some noise.
                    return;
                }

                fonts[key] = (fonts[key] || "") + value;
            });
    });

    return fonts;
}

function getFontsCss() {
    let style = "";

    const fonts = getFonts();
    Object.entries(fonts).forEach((entry) => {
        const [key, value] = entry;

        style += value;
    });

    return style;
}

export async function main() {
    const clientId = document.querySelector("meta[name='hellobb-client-id']")?.content;
    if (!clientId) {
        console.error("HelloBB client ID not found in the current page. Aborting script.");

        return;
    }

    let HELLOBB_URL_PREFIX = process.env.APP_URL;
    if (HELLOBB_URL_PREFIX.endsWith("/")) {
        HELLOBB_URL_PREFIX = HELLOBB_URL_PREFIX.substring(0, HELLOBB_URL_PREFIX.length - 1);
    }

    const MODAL_CONTAINER_ID = "hellobb-modal-iframe-container";

    function getModalIframeUrl(additionalQueryParams = {}) {
        const queryParams = {
            ...additionalQueryParams,

            url: window.location.href,
        };

        const url = `${HELLOBB_URL_PREFIX}/external-ecommerce/modal?${querystring.encode(queryParams)}`;

        return url;
    }

    function getButtonIframeUrl(additionalQueryParams = {}) {
        const queryParams = {
            ...additionalQueryParams,
        };

        const url = `${HELLOBB_URL_PREFIX}/external-ecommerce/button?${querystring.encode(queryParams)}`;

        return url;
    }

    const LOADING_IFRAME_URL = `${HELLOBB_URL_PREFIX}/index.loading.html`;

    let container = null;

    let loadingIframe = null;
    let realIframe = null;

    let customizations = null;

    const HANDLERS = {
        openAddToHelloBBModal(payload) {
            const options = {
                ...(payload || {}),

                modal: "1",
                client_id: clientId,
            };

            if (options?.mode === "limited") {
                const url = getModalIframeUrl(options);

                window.open(url, "_blank");

                return;
            }

            initContainer();

            realIframe = createFullPageIframe(getModalIframeUrl({
                ...(payload || {}),

                modal: "1",
            }));

            loadingIframe = createFullPageIframe(LOADING_IFRAME_URL);
            loadingIframe.style.display = "block";
        },
        close() {
            if (container) {
                container.remove();
            }

            loadingIframe = null;
            realIframe = null;
        },
        popup(payload) {
            const {
                url,
                width,
                height,
            } = payload;

            let windowFeatures = "popup";
            if (width && height) {
                windowFeatures += `,width=${width},height=${height}`;
            }

            window.open(url, "_blank", windowFeatures);
        },
        ready() {
            swapIframes();
        },
        reload(payload = {}) {
            HANDLERS.close();
            HANDLERS.openAddToHelloBBModal({
                ...(payload || {}),
            });
        },
        getCustomizations(payload, event) {
            sendMessage(event.source, "setCustomizations", customizations || DEFAULT_CUSTOMIZATIONS);
            sendMessage(event.source, "css", getFontsCss());
        },
        getHtml(payload, event) {
            sendMessage(event.source, "setHtml", (new XMLSerializer().serializeToString(window.document)));
        },
        replaceTab(payload) {
            console.log({ replaceTab: payload });
            window.location.href = payload.url;
        },
        openNewTab(payload) {
            console.log({ openNewTab: payload });
            window.open(payload.url, "_blank");
        }
    };

    window.addEventListener("message", (event) => {
        if (!event.data || typeof event.data !== "object") {
            return;
        }
        if (event.data.source !== "hellobb") {
            return;
        }

        if (!Object.hasOwn(HANDLERS, event.data.type)) {
            // Ignore unknown event types.
            return;
        }

        const handler = HANDLERS[event.data.type];

        handler(event.data.payload, event);
    });

    function initContainer() {
        container = document.getElementById(MODAL_CONTAINER_ID);
        if (container) {
            container.innerHTML = "";
        } else {
            container = document.createElement("div");
            container.setAttribute("id", MODAL_CONTAINER_ID);
            container.classList.add("hellobb-modal-container");
            container.addEventListener("click", (e) => {
                if (e.target !== container) {
                    return;
                }

                HANDLERS.close();
            });
            document.body.prepend(container);
        }
    }

    function swapIframes() {
        loadingIframe.remove();
        realIframe.style.display = "block";
    }

    function createFullPageIframe(url) {
        const iframe = document.createElement("iframe");
        iframe.setAttribute("style", "width: 100%; height: 100%; min-height: 100vh; background-color: transparent; overflow: auto; display: none;");
        iframe.setAttribute("scrolling", "yes");
        iframe.setAttribute("src", url);

        iframe.frameBorder = "0";
        iframe.allowTransparency = "true";

        container.appendChild(iframe);

        updateHellobbZIndex();

        return iframe;
    }

    function createIframe(url) {
        const iframe = document.createElement("iframe");
        iframe.setAttribute("style", "width: 100%; background-color: transparent; overflow: auto; display: none;");
        iframe.setAttribute("scrolling", "yes");
        iframe.setAttribute("src", url);

        iframe.frameBorder = "0";
        iframe.allowTransparency = "true";

        return iframe;
    }

    async function initializeButtons() {
        customizations = await getClientIdCustomizations(clientId);
        if (!customizations) {
            customizations = DEFAULT_CUSTOMIZATIONS;

            return [];
        }

        customizations = {
            ...DEFAULT_CUSTOMIZATIONS,
            ...customizations,
        };

        const initialized = Array.from(
            document.querySelectorAll(`[data-${ATTRIBUTE_PREFIX}-product]`)
        ).map((buttonContainer) => {
            // Skip this container if it already has an iframe.
            if (buttonContainer.querySelector("iframe")) {
                return null;
            }

            const buttonIframe = createIframe(
                getButtonIframeUrl(
                    getHellobbAttributes(buttonContainer.dataset)
                )
            );

            buttonIframe.style.display = "flex";
            buttonContainer.appendChild(buttonIframe);

            if (customizations.tooltipEnabled) {
                const tooltip = createTooltip({
                    container: buttonContainer,
                    retailerName: customizations.displayName || "esta tienda",
                });
            }

            return buttonContainer;
        }).filter((item) => {
            return !!item;
        });

        return initialized;
    }

    // Initialize buttons after the page finished loading.
    //
    // This ensures the buttons are initialized even if this script is loaded
    // too early.
    document.addEventListener("DOMContentLoaded", () => {
        initializeButtons();
    });

    // Initialize buttons now.
    //
    // This ensures the buttons are initialized even if the script is added
    // dynamically after the `DOMContentLoaded` event has already fired.
    return initializeButtons();
}
