import infoIconUrl from "../../assets/img/ico/ico-info-circle.svg";

const absoluteInfoIconUrl = (process.env.APP_URL || "").replace(/\/$/, "") + (infoIconUrl.replace("//", "/"));

export function createTooltip(options) {
    const {
        container,
        retailerName,
    } = options;

    const infoIcon = document.createElement("img");
    infoIcon.classList.add("hellobb-info");
    infoIcon.src = absoluteInfoIconUrl;
    container.appendChild(infoIcon);

    const tooltip = document.createElement("div");
    tooltip.classList.add("hellobb-tooltip");
    tooltip.innerHTML = `Crearás una Lista de Deseos Universal en la que podrás guardar productos de ${retailerName} y de cualquier otra tienda. Con la tecnología de HelloBB.`;
    document.body.appendChild(tooltip);

    function updateTooltipPosition() {
        const bodyRect = document.body.getBoundingClientRect();
        const rect = infoIcon.getBoundingClientRect();

        const triangleWidth = 18;

        const cssTop = rect.top - bodyRect.top;
        const cssLeft = rect.left - bodyRect.left + Math.floor(triangleWidth / 2) + 4;

        tooltip.style = `top: ${cssTop}px; left: ${cssLeft}px; opacity: 1; position: absolute;`;
    }

    infoIcon.addEventListener("mouseenter", () => {
        updateTooltipPosition();
    });

    infoIcon.addEventListener("mouseleave", () => {
        tooltip.style = "opacity: 0;";
    });

    return tooltip;
}
