// TODO: This file is no longer needed. All its contents can be moved to
// `src/store/actions.js`.

import axios from "axios";

const API_KEY = process.env.EXTERNAL_ECOMMERCE_API_KEY;
const API_URL_PREFIX = process.env.EXTERNAL_ECOMMERCE_API_URL_PREFIX;

const DEFAULT_HEADERS = {
    "x-functions-key": API_KEY,
};

function isErrorResponse(response) {
    return response.status >= 400 && response.status <= 599;
}

async function get(url) {
    const options = {
        headers: {
            ...DEFAULT_HEADERS,
        },
    };

    const response = await axios.get(url, options);

    return response;
}

export async function getClientIdCustomizations(clientId) {
    const response = await get(`${API_URL_PREFIX}/auth/${encodeURIComponent(clientId)}`);
    if (response.status === 404) {
        return null;
    }

    if (response.status !== 200) {
        throw new Error(`Unexpected HTTP response status: ${response.status}`);
    }

    return response.data;
}
